<template>
  <div class="component-wrapper">
    <b-card>
      <div class="d-flex">
        <div v-if="loader.agent" class="d-flex">
          <b-skeleton type="button" variant="danger" width="150px"></b-skeleton>
          <b-skeleton type="button" variant="danger" width="150px" class="ml-1"></b-skeleton>
          <b-skeleton type="button" variant="danger" width="150px" class="ml-1"></b-skeleton>
        </div>
        <div class="agent">
          <b-button v-for="x in parameter.agentList" :key="x.code" :variant="parameter.selectedAgent == x ? `danger` : `outline-danger`" class="mr-1" @click="parameter.selectedAgent = x">
            {{ x.description }}
          </b-button>
        </div>
        <div class="button ml-auto">
          <b-button :disabled="loader.batch" variant="info" @click.stop="updateAll()">
            <b-spinner v-if="loader.batch" small></b-spinner>
            Update All
          </b-button>
          <b-button class="ml-1" variant="dark" @click.stop="validateAll()">Validate All</b-button>
          <b-button class="ml-1" variant="primary" @click.stop="showDetail()">New Domain</b-button>
        </div>
      </div>
    </b-card>
    <b-card>
      <template #header>
        <span v-if="parameter.selectedAgent"
          >Showing Domain of <b>{{ parameter.selectedAgent.description }}</b></span
        >
        <div class="ml-auto">
          <b-input-group>
            <b-form-file v-model="file" accept=".xlsx" placeholder="Upload Domain Template" @input="uploadFile"></b-form-file>
            <template #append>
              <b-button variant="warning" @click.stop="downloadFile">Template</b-button>
            </template>
          </b-input-group>
        </div>
      </template>

      <template #default>
        <vue-element-loading :active="loader.table"></vue-element-loading>

        <b-table ref="domainTable" :items="filteredTable" :fields="table.fields" small fixed striped show-empty :current-page="table.paging.pageNo" :per-page="table.paging.pageSize">
          <template #top-row>
            <td>
              <b-input v-model="table.filter.domain" placeholder="Filter Domain" class="w-100" :debounce="300"></b-input>
            </td>
            <td></td>
            <td></td>
            <td>
              <b-form-select v-model="table.filter.isValid" :options="parameter.validOptions"></b-form-select>
            </td>
            <td></td>
          </template>

          <template #cell(domainUrl)="data">
            <div class="d-flex">
              <b-link :href="`https://${data.item.domainUrl}`" target="_blank" :title="`Open ${data.item.domainUrl}`">{{ data.item.domainUrl }}</b-link>

              <span class="ml-1 cursor-pointer text-info" @click.stop="copy(data.item.domainUrl)">
                <b-icon icon="files"></b-icon>
              </span>

              <b-link :href="`https://${data.item.domainUrl}/wp-admin`" :title="`Open ${data.item.domainUrl}/wp-admin`" target="_blank" class="ml-auto">
                <b-icon icon="gear"></b-icon>
              </b-link>
            </div>
          </template>
          <template #cell(wpUser)="data">
            <span>{{ data.item.wpUser }}</span>
            <span class="ml-1 cursor-pointer text-info" @click.stop="copy(data.item.wpUser)">
              <b-icon icon="files"></b-icon>
            </span>
          </template>
          <template #cell(wpPass)="data">
            <span>{{ data.item.wpPass }}</span>
            <span class="ml-1 cursor-pointer text-info" @click.stop="copy(data.item.wpPass)">
              <b-icon icon="files"></b-icon>
            </span>
          </template>
          <template #cell(isSetupValid)="data">
            <b-icon
              scale="1.5"
              :title="data.item.isSetupValid ? `Valid` : `Invalid`"
              :icon="data.item.isSetupValid ? `patch-check` : `exclamation-triangle`"
              :variant="data.item.isSetupValid ? `success` : `danger`"
            ></b-icon>
          </template>
          <template #cell(action)="data">
            <b-button v-if="!data.item.isSetupValid" :disable="data.item.loader" variant="dark" size="sm" @click.stop="validateSingle(data.item)">
              <b-spinner v-if="data.item.loader" small></b-spinner>
              <span v-else>Validate Setup</span>
            </b-button>
            <b-button v-if="data.item.isSetupValid" variant="secondary" size="sm" class="ml-1" :disable="data.item.updateLoader" @click.stop="updateSingle(data.item)">
              <b-spinner v-if="data.item.updateLoader" small></b-spinner>
              <span v-else>Update Content</span>
            </b-button>
            <b-button variant="warning" size="sm" class="ml-1" @click.stop="showDetail(data.item.domainUrl)">Edit</b-button>
            <b-button variant="danger" size="sm" class="ml-1" @click.stop="remove(data.item.domainUrl)"> Delete </b-button>
          </template>
        </b-table>
      </template>

      <template #footer>
        <div class="d-flex mt-auto align-items-center">
          <page-info :page-no="table.paging.pageNo" :page-size="table.paging.pageSize" :total="filteredTable.length" />
          <b-pagination v-model="table.paging.pageNo" :totalRows="filteredTable.length" :per-page="table.paging.pageSize" align="center" class="ml-auto"></b-pagination>
        </div>
      </template>
    </b-card>

    <b-modal v-model="modal.detail" :title="selectedDomain ? `Edit Domain` : `Add New Domain`" lazy centered hide-footer no-close-on-backdrop @hide="selectedDomain = false">
      <DomainDetail v-if="parameter.selectedAgent" :domainUrl="safeDomainCode" :agentCode="parameter.selectedAgent.code" @onCancel="cancel" @onSaveSuccess="saveSuccess"></DomainDetail>
    </b-modal>

    <b-modal v-model="modal.batch" :title="batchTitle" size="xl" lazy hide-footer no-close-on-backdrop>
      <b-card :title="`Failed Result ${failedDomainResult.length}`">
        <b-row>
          <b-col v-for="x in failedDomainResult" :key="x.domain" cols="2">
            <span class="mb-1">
              {{ x.domain }}
            </span>
          </b-col>
        </b-row>
      </b-card>

      <b-card :title="`Success Result ${successDomainResult.length}`">
        <b-row>
          <b-col v-for="x in successDomainResult" :key="x.domain" cols="2">
            <span class="mb-1">
              {{ x.domain }}
            </span>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import api from "@api";
import DomainDetail from "./Detail.vue";

export default {
  name: "DomainList",
  components: {
    DomainDetail,
  },
  data() {
    return {
      parameter: {
        agentList: [],
        selectedAgent: null,
        validOptions: [
          { text: `Valid`, value: true },
          { text: `Invalid`, value: false },
        ],
      },
      file: null,
      selectedDomain: null,
      table: {
        filter: {
          domain: null,
          isValid: null,
        },
        items: [],
        fields: [
          { key: "domainUrl", label: "Domain", sortable: true },
          { key: "wpUser", label: "Username" },
          { key: "wpPass", label: "Password" },
          { key: "isSetupValid", label: "Setup Valid ?", sortable: true, class: "text-center" },
          { key: "action", label: "", class: `text-right` },
        ],
        paging: {
          pageNo: 1,
          pageSize: 25,
        },
      },
      loader: { table: false, agent: false },
      modal: { detail: false, batch: false },
      batchResult: [],
      batchTitle: null,
    };
  },
  watch: {
    "parameter.selectedAgent": function () {
      this.getDomainList();
    },
  },

  computed: {
    safeDomainCode() {
      return this.selectedDomain ? this.selectedDomain : null;
    },
    filteredTable() {
      let retVal = this.table.items;
      if (this.table.filter.domain) {
        retVal = retVal.filter(x => x.domainUrl.toLowerCase().includes(this.table.filter.domain.toLowerCase()));
      }
      if (this.table.filter.isValid != null) {
        retVal = retVal.filter(x => x.isSetupValid == this.table.filter.isValid);
      }
      return retVal;
    },
    successDomainResult() {
      return this.batchResult.filter(x => x.result);
    },
    failedDomainResult() {
      return this.batchResult.filter(x => !x.result);
    },
  },
  async mounted() {
    await this.getAgentList();
  },
  methods: {
    async showDetail(domain) {
      this.selectedDomain = domain;
      this.modal.detail = true;
    },
    async getAgentList() {
      this.loader.agent = true;
      try {
        const response = await api.get(`agent`);
        this.parameter.agentList = response;
        this.parameter.selectedAgent = response[0];
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader.agent = false;
      }
    },
    async getDomainList() {
      this.loader.table = true;
      try {
        const response = await api.get(`domain`, { agentCode: this.parameter.selectedAgent.code });
        this.table.items = response;
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader.table = false;
      }
    },
    async saveSuccess() {
      this.modal.detail = false;
      this.getDomainList();
    },
    async cancel() {
      this.modal.detail = false;
    },
    async remove(domain) {
      let isConfirmed = await this.showConfirm(`Are you sure want to delete this domain ?`);
      if (!isConfirmed) return;
      await api.delete(`domain/${domain}`);
      this.showToast(`success`, `Delete Success`);
      this.getDomainList();
    },
    downloadFile() {
      const link = document.createElement("a");
      link.href = `${process.env.BASE_URL}DomainUploadTemplate.xlsx`;
      link.download = "DomainUploadTemplate.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async uploadFile() {
      if (!this.file) {
        return;
      }

      try {
        this.loader.table = true;
        const formData = new FormData();
        formData.append("file", this.file);
        await api.upload(`domain/upload/${this.parameter.selectedAgent.code}`, formData);
        this.getDomainList();
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader.table = false;
        this.file = null;
      }
    },
    async validate(record) {
      record.loader = true;
      this.$refs.domainTable.refresh();
      try {
        let result = await api.put(`domain/validate/${record.domainUrl}`);
        record.isSetupValid = result;
        return { domain: record.domainUrl, result };
      } catch (error) {
        this.showToast(`error`, error);
        return false;
      } finally {
        record.loader = false;
        this.$refs.domainTable.refresh();
      }
    },
    async validateSingle(record) {
      let result = await this.validate(record);
      if (result) this.showToast(`success`, `Validate ${record.domainUrl} Success`);
      else this.showToast(`error`, `Validate ${record.domainUrl} Failed`);
    },
    async validateAll() {
      this.loader.batch = true;
      let validationPromises = [];
      let domainList = this.table.items;
      for (let i = 0; i < domainList.length; i++) {
        validationPromises.push(this.validate(domainList[i]));
      }
      this.batchTitle = `Validate Domain Status`;
      try {
        const results = await Promise.all(validationPromises);
        this.batchResult = results;
        this.modal.batch = true;
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader.batch = false;
      }
    },
    async updateContent(record) {
      record.updateLoader = true;
      this.$refs.domainTable.refresh();
      try {
        let result = await api.get(`domain/updateContent/${record.domainUrl}`);

        return { domain: record.domainUrl, result };
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        record.updateLoader = false;
        this.$refs.domainTable.refresh();
      }
    },
    async updateSingle(record) {
      let result = await this.updateContent(record);
      if (result) this.showToast(`success`, `Update ${record.domainUrl} Success`);
      else this.showToast(`error`, `Update ${record.domainUrl} Failed`);
    },
    async updateAll() {
      this.loader.batch = true;
      let domainList = [...this.table.items.filter(x => x.isSetupValid)];
      let batchSize = 1;
      let delay = 1;
      this.batchResult = [];
      this.batchTitle = `Update Domain Status`;

      const delayPromise = ms => new Promise(resolve => setTimeout(resolve, ms));

      try {
        for (let i = 0; i < domainList.length; i += batchSize) {
          let batch = domainList.slice(i, i + batchSize);
          let updatePromises = batch.map(record => this.updateContent(record));

          try {
            const results = await Promise.all(updatePromises);
            this.batchResult.push(...results); // Extend batchResult with the new results
            this.modal.batch = true;
          } catch (error) {
            this.showToast(`error`, error);
          }

          if (i + batchSize < domainList.length) {
            await delayPromise(delay);
          }
        }
      } finally {
        this.loader.batch = false;
      }
    },
  },
};
</script>
